import './footer.scss';
import logo from '../../images/cotechie_logo_white.png';

export const Footer = () => {
  return (
    <div className="footerContainer">
      <img className="logoWrapper" src={logo} />
      <div>대표자명: 이준성</div>
      <div>이메일: cotechie.gonggija@gmail.com</div>
      <div>

      </div>
      <div className="copyrightWrapper">
        <span className="material-icons md-12">
          copyright
        </span>
        <div>2023 공동기술자. All rights reserved.</div>
      </div>
    </div>
  );
}