export const Greeting = () => {
  return (
    <div className="greetingContainer">
      <p>
      안녕하세요. 공동기술자의 공기자입니다.
      </p>
      <p>
      “공동기술자”라는 단어는 사전에 없는 창작 단어로 “자신의 기술을 이용해 여러 기술자들과 함께 공통된 목표를 이뤄나가는 사람”이라는 뜻에서 지은 이름 입니다.
      </p>
      <p>
      기술자라고 하면 보통 전문적인 기술을 가진 사람을 통칭 하지만, 일반 SNS 유저(user)들의 사용 후기와 홍보들이 온라인에서 효과를 낸다면 그들 또한 막강한 마케팅 파워를 가진 기술자가 아닐까 생각 합니다.
      </p>
      <p>
      이렇듯 여러 분야에 강점이 있는 일반인은 수도 없이 많고, 공동기술자는 이런 기술자들과 여러 프로젝트를 진행하여 기술자들이 부수익을 창출할 수 있도록 돕는 플랫폼입니다.
      </p>
      <p>
      공동기술자는 대한민국에서 품질 좋은 물품을 생산하는 생산자를 도와 여러 채널을 통해 국내 판매는 물론 해외 판매하는 것을 목표로 하고 있습니다.
      </p>
      <p>
      그 첫 번째 프로젝트로 &lt;체험단 기술자&gt;를 모집합니다.
      </p>
    </div>
  );
}