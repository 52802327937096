import { Tooltip } from 'react-tooltip'
import Button from 'react-bootstrap/Button';
import useCopyToClipboard from '../../../hooks/useCopyToClipboard';
import './contactDetail.scss';

export enum LinkType {
  Url,
  Email,
  Text
}

type ContactDetailProps = {
  title: string;
  link: string;
  linkType: LinkType;
  currentValue: string;
  furtherInfo?: string;
  onCopy: () => void; 
}

export const ContactDetail = ({
  title,
  link,
  linkType,
  currentValue,
  furtherInfo,
  onCopy
}: ContactDetailProps) => {
  const [value, copy] = useCopyToClipboard();

  const handleCopy = () => {
    copy(link);
    onCopy();
  }

  const getLink = () => {
    switch(linkType) {
      case LinkType.Url:
        return (
          <a href={link} target="_blank" data-tooltip-id="linkDescription" data-tooltip-content="바로가기" data-tooltip-place="top">
            <span className="material-icons">
              open_in_new
            </span>
          </a>
        );
      case LinkType.Email:
        return (
          <a href={`mailto:${link}`} data-tooltip-id="linkDescription" data-tooltip-content="이메일 보내기" data-tooltip-place="top">
            <span className="material-symbols-outlined">
              outgoing_mail
            </span>
          </a>
        );
      default: //Text
        return <div>{link}</div>
    }
  }

  return (
    <div className="contactDetailContainer">
      <div>
        <div className="detailsWrapper">
          <div className="titleWrapper">{title}</div>
          {getLink()}
          <Tooltip id="linkDescription"/>
          <div className="copyLinkDiv" onClick={handleCopy} data-tooltip-id="linkDescription" data-tooltip-content="복사하기" data-tooltip-place="top">
            <span className="material-symbols-outlined">
              content_copy
            </span>
          </div>
          {!!currentValue && currentValue === value
            ?
              <span className="checkIcon material-symbols-outlined text-success">
                done
              </span>  
            : <span className="checkIcon"/>
          }
        </div>
      </div>
      <div className="link">{link}</div>
      {!!furtherInfo &&
        <div className="furtherInfo">{furtherInfo}</div>
      }
    </div>
  );
}