import './homeProject1.scss';
import advert from '../../../images/adProject1.png';
import { ContactDetail, LinkType } from "./contactDetail";
import { useEffect, useState } from "react";
import uuid from 'react-uuid';
import { Greeting } from './greeting';

export const HomeProject1 = () => {
  const [copiedValue, setCopiedValue] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(`https://www.cotechie.com/?code=${uuid()}`);
  }, []);
  
  const valueToCopyInstagram = 'https://www.instagram.com/cotechie.gonggija';
  const valueToCopySmartstore = 'https://smartstore.naver.com/gonggija';
  const valueToCopyEmail = 'cotechie.gonggija@gmail.com';

  return (
    <div>
      <Greeting />
      <div className="imgWrapper">
        <img src={advert} />
      </div>
      <ContactDetail
        title={'인스타그램'}
        link={valueToCopyInstagram}
        linkType={LinkType.Url}
        currentValue={copiedValue}
        onCopy={() => setCopiedValue(valueToCopyInstagram)}      
      />
      <ContactDetail
        title={'스마트스토어'}
        link={valueToCopySmartstore}
        linkType={LinkType.Url}
        currentValue={copiedValue}
        onCopy={() => setCopiedValue(valueToCopySmartstore)}      
      />
      <ContactDetail
        title={'공유 URL'}
        link={url}
        linkType={LinkType.Url}
        currentValue={copiedValue}
        furtherInfo={'다음 방문 시 값이 변경됩니다. 복사하기를 누른 뒤 본인 디바이스에 저장 후 공유하시기 바랍니다.'}
        onCopy={() => setCopiedValue(url)}      
      />
      <ContactDetail
        title={'이메일'}
        link={valueToCopyEmail}
        linkType={LinkType.Email}
        currentValue={copiedValue}
        onCopy={() => setCopiedValue(valueToCopyEmail)}      
      />
    </div>
  );
}