import { Footer } from "./footer/footer";
import { Header } from "./header/header";
import { About } from "./main/about";
import { HomeProject1 } from "./main/homeProject1/homeProject1";
import './viewport.scss';

export enum ViewType {
  Home,
  About
}

type MainViewProps = {
  viewType: ViewType
};

export const Viewport = ({
  viewType
}: MainViewProps) => {
  const getMainView = (viewType: ViewType) => {
    if (viewType === ViewType.About)
      return <About /> 
    
    return <HomeProject1 />;
  }

  return (
    <>
      <Header />
      <div className="mainViewContainer">
        {getMainView(viewType)}
      </div>
      <Footer />
    </>
  );
}