import './App.css';
import { Router, Link, Route, Routes } from 'react-router-dom';
import { Viewport, ViewType } from './components/viewport';

function App() {
  return (
    <div>
      <div>
        {/* <nav>
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/bar">Bar</Link>
        </nav> */}
        <Routes>
          <Route path="/" element={<Viewport viewType={ViewType.Home} />} />
          {/* <Route path="/about" element={<About />} /> */}
        </Routes>
      </div>
    </div>
  );
}

export default App;
